<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center justify-space-between"
      >
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
      </v-col>
    </v-row>
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-if="ehFinalizar"
        class="col-12 col-md-6"
        :label="
          $t('modulos.roteiro_execucao_envio_material.formulario.veiculo')
        "
        disabled
        :value="form.veiculo?.text"
      />
      <InputVeiculos
        v-else
        v-model="form.veiculo"
        :multiplos="false"
        class="col-12 col-md-6"
        apenas-disponivel
        :label="
          $t('modulos.roteiro_execucao_envio_material.formulario.veiculo')
        "
      />
      <input-text
        v-if="ehFinalizar"
        v-model="form.kmChegada"
        class="col-12 col-md-6"
        type="number"
        :min="0"
        :label="
          $t('modulos.roteiro_execucao_envio_material.formulario.kmChegada')
        "
        :placeholder="
          $t('modulos.roteiro_execucao_envio_material.formulario.kmChegada')
        "
        :regras-personalizadas="regraKmChegadaMaiorQueKmSaida()"
        obrigatorio
      />
      <input-text
        v-else
        v-model="form.kmSaida"
        class="col-12 col-md-6"
        type="number"
        :label="
          $t('modulos.roteiro_execucao_envio_material.formulario.kmSaida')
        "
        :min="0"
        :regras-personalizadas="regraKmSaida()"
        obrigatorio
      />
      <input-textarea
        v-model="form.observacao"
        class="col-12"
        :label="
          $t('modulos.roteiro_execucao_envio_material.formulario.observacao')
        "
        :obrigatorio="false"
      />
      <accordion-padrao
        :alterar-cor="true"
        cor-azul-texto
        :titulo="
          $t(
            'modulos.roteiro_execucao_envio_material.formulario.checklistVeiculo'
          )
        "
        class="accordion-padrao col-12 mb-5"
      >
        <span>{{
          this.$t(
            'modulos.roteiro_execucao_envio_material.formulario.checklistMensagem'
          )
        }}</span>
        <tabela-padrao
          v-model="tabela.selecionados"
          :dados="tabela.dados"
          class="mt-2"
          :colunas="tabela.colunas"
          :por-pagina="tabela.porPagina"
          :quantidade-paginas="tabela.quantidadePaginas"
          sem-paginacao
          sem-acoes
          :mobile-breakpoint="1"
        />
      </accordion-padrao>
    </v-form>
    <v-divider class="my-5" />

    <template>
      <div class="d-flex align-center justify-center justify-md-end">
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2 flex flex-grow-1 flex-md-grow-0"
          btn100
          @click="cancelar"
        >
          <v-icon class="mr-1">
            $mdiCancel
          </v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <template>
          <botao-padrao
            v-if="ehFinalizar"
            class="flex-grow-1 flex-md-grow-0"
            btn100
            :tooltip="tooltip"
            :disabled="podeIniciar"
            @click="finalizar"
          >
            <v-icon class="mr-1">
              $mdiContentSaveOutline
            </v-icon>
            {{ $t('geral.botoes.salvar') }}
          </botao-padrao>
          <botao-padrao
            v-else
            class="flex-grow-1 flex-md-grow-0"
            btn100
            :tooltip="tooltip"
            :disabled="podeIniciar"
            @click="iniciar"
          >
            <v-icon>$playCircleOutline</v-icon>
            {{ $t('modulos.roteiro_execucao_envio_material.botoes.iniciar') }}
          </botao-padrao>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { InputVeiculos } from '@/components/inputs';
import { AgendamentoExecutarViagemModel } from '@/common/models/cadastros/AgendamentoExecutarViagemModel';
import AgendamentoService from '@/common/services/cadastros/AgendamentoService.js';
import { AgendamentoModel } from '@/common/models/cadastros/AgendamentoModel.js';


export default {
  props: ['id'],
  components: {
    InputVeiculos,
  },
  data() {
    return {
      roteiro: {},
      roteiroId: null,
      form: new AgendamentoExecutarViagemModel({}),
      agendamento: new AgendamentoModel({}),
      veiculoWatcher: null,
      podeExecutarWatcher: true,
      valido: false,
      tabela: {
        selecionados: [],
        dados: [
          {
            id: 1,
            flag: 'flagNivelOleo',
            nome: this.$t(
              'modulos.roteiro_execucao_envio_material.formulario.tabela.nivelOleo'
            ),
          },
          {
            id: 2,
            flag: 'flagAguaRadiador',
            nome: this.$t(
              'modulos.roteiro_execucao_envio_material.formulario.tabela.aguaRadiador'
            ),
          },
          {
            id: 3,
            flag: 'flagAguaParabrisa',
            nome: this.$t(
              'modulos.roteiro_execucao_envio_material.formulario.tabela.aguaParabrisa'
            ),
          },
          {
            id: 4,
            flag: 'flagFreiosPneus',
            nome: this.$t(
              'modulos.roteiro_execucao_envio_material.formulario.tabela.freiosPneus'
            ),
          },
          {
            id: 5,
            flag: 'flagFaroisLuzes',
            nome: this.$t(
              'modulos.roteiro_execucao_envio_material.formulario.tabela.faroisLuzes'
            ),
          },
        ],
        colunas: [
          {
            value: 'nome',
            text: this.$t(
              'modulos.roteiro_execucao_envio_material.formulario.tabela.selecionarTodos'
            ),
            sortable: false,
          },
        ],
      },
    };
  },
  computed: {
    tituloFormulario() {
      const titulo = this.ehFinalizar
        ? this.$t('modulos.agendamento.titulos.finalizar_viagem')
        : this.$t('modulos.agendamento.titulos.iniciar_viagem');

      return titulo + ' - ' + this.agendamento?.codigo;
    },
    podeIniciar() {
      if (!this.valido) return true;
      if (
        this.tabela.selecionados.length < 5 &&
        this.form.observacao?.length > 0
      )
        return false;
      if (this.tabela.selecionados.length < 5) return true;

      return false;
    },
    ehFinalizar() {
      const { name } = this.$route;
      return name === 'agendamento-finalizar-viagem';
    },
    tooltip() {
      if (!this.valido) {
        return this.$t(
          'modulos.roteiro_execucao_envio_material.mensagem.preenchaTodosCampos'
        );
      }
      if (
        this.tabela.selecionados.length < 5 &&
        this.form.observacao.length < 1
      ) {
        return this.$t(
          'modulos.roteiro_execucao_envio_material.mensagem.selecioneTodos'
        );
      }
      return '';
    },
  },
  watch: {
    form: {
      handler: function () {
        this.valido = this.$refs.form.validate();
      },
      deep: true,
    },
  },
  mounted() {
    this.buscar(this.id)
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.ehFinalizar
        ? this.$t('modulos.agendamento.titulos.finalizar_viagem')
        : this.$t('modulos.agendamento.titulos.iniciar_viagem')
    );
  },
  methods: {
    buscar: async function (id) {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Buscando agendamento'
      );
      await AgendamentoService.buscar(id)
        .then((response) => {
          this.agendamento = new AgendamentoModel(response.data);
          this.form.veiculo = this.agendamento.veiculo;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.$router.push({
            name: 'agendamento',
          });
    },
    iniciar: function () {
      const valido = this.$refs.form.validate();
      if (!valido) return;
      this.form.flags = this.tabela.selecionados;
      
      this.$store.dispatch('Layout/iniciarCarregamento');
      AgendamentoService.iniciarViagem(
        this.id,
        this.form.request
      )
        .then(() => {
          this.$router.push({
            name: 'agendamento',
          });

          this.toastSucesso(this.$t('modulos.agendamento.execucao_viagem_sucesso'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    finalizar: function () {
      const valido = this.$refs.form.validate();
      if (!valido) return;
      this.form.flags = this.tabela.selecionados;
      this.$store.dispatch('Layout/iniciarCarregamento');
      AgendamentoService.finalizarViagem(
        this.id,
        this.form.request
      )
        .then(() => {
          this.$router.push({
            name: 'agendamento',
          });
          this.toastSucesso(this.$t('modulos.agendamento.execucao_viagem_sucesso'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    regraKmChegadaMaiorQueKmSaida: function () {
      if (!this.form.kmChegada) return [];

      const regraValor = (v) => {
        if (parseInt(v) < parseInt(this.form.kmSaida))
          return this.$t(
            'modulos.roteiro_execucao_envio_material.mensagem.kmchegadaNaoPodeSerMenor',
            { kmSaida: this.form.kmSaida }
          );

        return true;
      };
      let regraNaoPodeDecimal = (v) => {
        if (v % 1 != 0) {
          return this.$t(
            'modulos.roteiro_execucao_envio_material.mensagem.naoPodeDecimal'
          );
        }
        return true;
      };

      return [regraValor, regraNaoPodeDecimal];
    },
    regraKmSaida: function () {
      if (!this.form.kmSaida) return [];

      let regraNaoPodeDecimal = (v) => {
        if (v % 1 != 0) {
          return this.$t(
            'modulos.roteiro_execucao_envio_material.mensagem.naoPodeDecimal'
          );
        }
        return true;
      };

      return [regraNaoPodeDecimal];
    },
  },
};
</script>
