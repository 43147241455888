import { cloneDeep } from 'lodash';
import { DropdownModelDoisParametros } from '../geral/DropdownModelDoisParametros';

export class AgendamentoModel {
  constructor({
    id,
    codigo,
    ordemServico = null,
    contratante = null,
    solicitante = null,
    contatoContratante = null,
    tipoVisita = null,
    prioridade = null,
    veiculo = null,
    observacao,
    colaboradores = [],
    instrumentos = [],
    instrumentoPadrao = [],
    datasSugeridas = [{ ordem: 1 }, { ordem: 2 }, { ordem: 3 }],
    historico = [],
    dataHoraInicial = '',
    dataHoraFinal = '',
    checkIn,
    atendimento,
    checkOut,
    dataSugerida1 = new DataSugerida({ ordem: 1 }),
    dataSugerida2 = new DataSugerida({ ordem: 1 }),
    dataSugerida3 = new DataSugerida({ ordem: 1 }),
    resumo = [],
    flagPodeCheckIn = false,
    flagPodeCheckOut = false,
    flagPodeIniciarAtendimento = false,
    flagPodeFinalizarAtendimento = false,
  }) {
    this.id = id;
    this.codigo = codigo;
    this.ordemServico = ordemServico;
    if (ordemServico)
      this.ordemServico = new DropdownModelDoisParametros(
        ordemServico,
        'codigo'
      );
    this.contratante = contratante;
    if (contratante) {
      this.contratante = new DropdownModelDoisParametros(
        contratante,
        'nome',
        'flagParticipanteConta'
      );
    }
    this.contatoContratante = contatoContratante;
    if (contatoContratante) {
      this.contatoContratante = new DropdownModelDoisParametros(
        contatoContratante,
        'nome'
      );
    }
    this.solicitante = solicitante;
    if (solicitante) {
      this.solicitante = new DropdownModelDoisParametros(solicitante, 'nome');
    }
    this.tipoVisita = tipoVisita;
    if (tipoVisita) {
      this.tipoVisita = new DropdownModelDoisParametros(tipoVisita, 'nome');
    }
    this.prioridade = prioridade;
    if (prioridade) {
      this.prioridade = new DropdownModelDoisParametros(prioridade, 'nome');
    }
    this.veiculo = veiculo;
    if (veiculo) {
      this.veiculo = new DropdownModelDoisParametros(veiculo, 'nome');
    }
    this.observacao = observacao;
    this.colaboradores = colaboradores;
    if (colaboradores) {
      this.colaboradores = new DropdownModelDoisParametros(
        colaboradores,
        'nome'
      );
    }
    this.instrumentoPadrao = instrumentoPadrao;
    if (instrumentos) {
      this.instrumentoPadrao = new DropdownModelDoisParametros(
        instrumentos?.filter((i) => i.flagPadrao),
        'codigoTag'
      );
      this.instrumentos = [...instrumentos?.filter((i) => !i.flagPadrao)];
    }

    this.datasSugeridas = cloneDeep(datasSugeridas);

    this.dataSugerida1 = dataSugerida1;
    this.dataSugerida2 = dataSugerida2;
    this.dataSugerida3 = dataSugerida3;

    this.dataSugerida1 = new DataSugerida(
      datasSugeridas.find((x) => x.ordem === 1) || { ordem: 1 }
    );
    this.dataSugerida2 = new DataSugerida(
      datasSugeridas.find((x) => x.ordem === 2) || { ordem: 2 }
    );
    this.dataSugerida3 = new DataSugerida(
      datasSugeridas.find((x) => x.ordem === 3) || { ordem: 3 }
    );

    this.historico = historico;

    this.dataHoraInicial = dataHoraInicial;
    this.dataHoraFinal = dataHoraFinal;
    this.dataInicial = dataHoraInicial?.substring(0, 10);
    this.horaInicial = dataHoraInicial?.substring(11, 16);
    this.dataFinal = dataHoraFinal?.substring(0, 10);
    this.horaFinal = dataHoraFinal?.substring(11, 16);

    this.checkIn = checkIn;
    this.atendimento = atendimento;
    this.checkOut = checkOut;

    this.resumo = resumo;
    this.flagPodeCheckIn = flagPodeCheckIn;
    this.flagPodeCheckOut = flagPodeCheckOut;
    this.flagPodeIniciarAtendimento = flagPodeIniciarAtendimento;
    this.flagPodeFinalizarAtendimento = flagPodeFinalizarAtendimento;
  }
  get request() {
    let retorno = cloneDeep(this);
    retorno.contratanteId = retorno.contratante?.value;
    retorno.solicitanteId = retorno.solicitante?.value;
    retorno.contatoContratanteId = retorno.contatoContratante?.value;
    retorno.colaboradoresIds = retorno.colaboradores?.map(
      (colaborador) => colaborador.value
    );
    retorno.instrumentosIds = [];
    retorno.instrumentos?.forEach((instrumento) => {
      retorno.instrumentosIds.push(instrumento.id);
    });
    retorno.tipoVisitaId = retorno.tipoVisita?.value;
    retorno.prioridadeId = retorno.prioridade?.value;
    retorno.instrumentoPadrao?.forEach((instrumento) => {
      retorno.instrumentosIds.push(instrumento.value);
    });
    let datasSugeridas = [
      retorno.dataSugerida1,
      retorno.dataSugerida2,
      retorno.dataSugerida3,
    ];

    retorno.datasSugeridas = datasSugeridas
      .map((data) => {
        if (data.dataInicial) {
          let dataInicial = data.dataInicial;
          let dataFinal = data.dataFinal;
          if (data.horaInicial)
            dataInicial =
              `${data.dataInicial?.substring(0, 11)}` +
              `${data.horaInicial}` +
              ':00.000';

          if (data.horaFinal)
            dataFinal =
              `${data.dataFinal?.substring(0, 11)}` +
              `${data.horaFinal}` +
              ':00.000';
          return {
            ...data,
            dataInicial: dataInicial,
            dataFinal: dataFinal,
          };
        }
      })
      .filter((elemento) => {
        return elemento?.dataInicial;
      });

    retorno.veiculoId = retorno.veiculo?.value;

    retorno.dataHoraInicial =
      `${retorno?.dataSugerida1?.dataInicial.substring(0, 11)}` +
      `${retorno?.dataSugerida1?.horaInicial}` +
      ':00.000';
    retorno.dataHoraFinal =
      `${retorno?.dataSugerida1?.dataFinal.substring(0, 11)}` +
      `${retorno?.dataSugerida1?.horaFinal}` +
      ':00.000';
    if (
      !retorno.dataSugerida1?.dataInicial ||
      !retorno.dataSugerida1?.horaInicial ||
      !retorno.dataSugerida1?.dataFinal ||
      !retorno.dataSugerida1?.horaFinal
    ) {
      retorno.dataHoraInicial = null;
      retorno.dataHoraFinal = null;
    }

    return retorno;
  }

  get programar() {
    let retorno = cloneDeep(this);
    retorno.tipoVisitaId = retorno.tipoVisita?.value;
    retorno.prioridadeId = retorno.prioridade?.value;
    retorno.dataHoraInicial =
      `${retorno?.dataInicial.substring(0, 11)}` +
      `${retorno?.horaInicial}` +
      ':00.000Z';
    retorno.dataHoraFinal =
      `${retorno?.dataFinal.substring(0, 11)}` +
      `${retorno?.horaFinal}` +
      ':00.000Z';
    retorno.veiculoId = retorno.veiculo?.value;
    retorno.colaboradoresIds = retorno.colaboradores?.map(
      (colaborador) => colaborador.value
    );

    retorno.instrumentosIds = [];
    retorno.instrumentoPadrao?.forEach((instrumento) => {
      retorno.instrumentosIds.push(instrumento.value);
    });

    return retorno;
  }

  get confirmar() {
    let retorno = cloneDeep(this);
    retorno.tipoVisitaId = retorno.tipoVisita?.value;
    retorno.prioridadeId = retorno.prioridade?.value;
    retorno.dataHoraInicial =
      `${retorno?.dataInicial.substring(0, 11)}` +
      `${retorno?.horaInicial}` +
      ':00.000Z';
    retorno.dataHoraFinal =
      `${retorno?.dataFinal.substring(0, 11)}` +
      `${retorno?.horaFinal}` +
      ':00.000Z';

    return retorno;
  }

  get alterarInstrumentos() {
    let retorno = cloneDeep(this);
    retorno.instrumentosIds = [];
    retorno.instrumentos?.forEach((instrumento) => {
      retorno.instrumentosIds.push(instrumento.id);
    });
    return retorno;
  }

  get checkOutJustificativa() {
    let retorno = cloneDeep(this);

    const justificativas =
      retorno.resumo?.instrumentosPrevistosNaoExecutados.map((item) => {
        return {
          ordemServicoInstrumentoId: item.ordemServicoInstrumentoId,
          manutencaoPecaCalibracao: item.manutencaoPecaCalibracao,
          id: item.servicoPecaId,
          justificativa: item.justificativa,
        };
      });

    return {
      id: retorno.id,
      dataHoraCheckOut: new Date(),
      justificativas,
    };
  }
}

class DataSugerida {
  constructor({
    id,
    dataHoraInicial = '',
    dataInicial = '',
    horaInicial = '',
    dataHoraFinal = '',
    dataFinal = '',
    horaFinal = '',
    ordem,
  }) {
    this.id = id;
    this.dataHoraInicial = dataHoraInicial;
    this.dataHoraFinal = dataHoraFinal;
    this.dataInicial = dataInicial;
    this.horaInicial = horaInicial;
    this.dataFinal = dataFinal;
    this.horaFinal = horaFinal;
    this.ordem = ordem;
  }
}
