import _ from 'lodash';

const flagsTabela = ['flagNivelOleo',
  'flagAguaRadiador',
  'flagAguaParabrisa',
  'flagFreiosPneus',
  'flagFaroisLuzes']

export class AgendamentoExecutarViagemModel {
  constructor({ veiculo,
    kmSaida,
    kgChegada,
    observacao = '',
    notaFiscal,
    flags = [] }) {
    this.veiculo = veiculo;
    this.kmSaida = kmSaida;
    this.kgChegada = kgChegada;
    this.observacao = observacao;
    this.flags = flags;
    this.notaFiscal = notaFiscal;
  }

  get request() {
    let retorno = _.cloneDeep(this);

    flagsTabela.forEach(flag => {
      retorno[flag] = true;
    })

    retorno.veiculoId = retorno.veiculo?.value
    delete retorno.flags;

    return retorno;
  }
}

