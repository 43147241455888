import { BasicoApi } from '@common/api';
const AgendamentoService = {
  listar: function (parametros) {
    return BasicoApi.get()('/agendamento', parametros);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/agendamento/${form.id}`, form);
    return BasicoApi.post()('/agendamento', form);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/agendamento/${id}`);
  },
  cancelar: function (id) {
    return BasicoApi.put()(`/agendamento/${id}/cancelar`);
  },
  programar: function (form) {
    return BasicoApi.put()(`/agendamento/${form.id}/programar`, form);
  },
  confirmar: function (form) {
    return BasicoApi.put()(`/agendamento/${form.id}/confirmar`, form);
  },
  aprovar: function (id) {
    return BasicoApi.put()(`/agendamento/${id}/assinatura-cliente`);
  },
  checkin: function (id) {
    return BasicoApi.put()(`/agendamento/${id}/checkin`);
  },
  checkout: function (form) {
    return BasicoApi.put()(`/agendamento/${form.id}/checkout`,form);
  },
  iniciarAtendimento: function (id) {
    return BasicoApi.put()(`/agendamento/${id}/iniciar-atendimento`);
  },
  finalizarAtendimento: function (id) {
    return BasicoApi.put()(`/agendamento/${id}/finalizar-atendimento`);
  },
  aassinaturaCliente: function (id) {
    return BasicoApi.put()(`/agendamento/${id}/assinatura-cliente`);
  },
  alterarInstrumentos: function (form) {
    return BasicoApi.put()(`/agendamento/${form.id}/alterar-instrumentos`, form);
  },
  analiseCritica: function (id,form) {
    return BasicoApi.post()(`/agendamento/${id}/analise-critica/reagendar`, form);
  },
  buscarAnaliseCritica: function (id) {
    return BasicoApi.get()(`/agendamento/${id}/analise-critica`);
  },
  buscarDatasProgramadas: function(colaboradoresId = [], instrumentosPadroesId = [], veiculoId, dataInicial, quantidadeDias = 5)
  {
    let link = `/agendamento/datas-programadas?veiculoId=${veiculoId}&dataInicial=${dataInicial}&quantidadeDias=${quantidadeDias}`
    if(instrumentosPadroesId.length) link = `${link}&instrumentosPadroesIds=${instrumentosPadroesId.join('&instrumentosPadroesIds=')}`;
    if(colaboradoresId.length) link = `${link}&colaboradoresIds=${colaboradoresId.join('&colaboradoresIds=')}`;
    return BasicoApi.get()(link);
  },
  iniciarViagem: function (id, form) {
    return BasicoApi.post()(`/agendamento/${id}/viagem/iniciar`, form);
  },
  finalizarViagem: function (id, form) {
    return BasicoApi.post()(`/agendamento/${id}/viagem/finalizar`, form);
  },
};

export default AgendamentoService;
